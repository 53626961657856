import React from 'react'
import styled from 'styled-components'

const ToggleElement = styled.span<{ isActive?: boolean; isOnSwitch?: boolean }>`
  padding: 0.25rem 0.5rem;
  border-radius: 14px;
  background: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.primary1 : theme.text4) : 'none')};
  color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.white : theme.text2) : '#651B97')};
  font-size: 0.825rem;
  font-weight: 400;
  box-shadow: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? 'inset 0px -2px 0px 0px rgba(0,0,0,0.2)' : 'inset 0px -2px 0px 0px rgba(0,0,0,0.2)') : '')};
`

const StyledToggle = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
  border-radius: 16px;
  border: none;
  display: flex;
  font-family: 'Alibaba-PuHuiTi-Medium';
  width: fit-content;
  cursor: pointer;
  outline: none;
  padding: 0;
  background-color: #EDEAF3;
`

export interface ToggleProps {
  id?: string
  isActive: boolean
  toggle: () => void
}

export default function Toggle({ id, isActive, toggle }: ToggleProps) {
  return (
    <StyledToggle id={id} isActive={isActive} onClick={toggle}>
      <ToggleElement isActive={isActive} isOnSwitch={true}>
        On
      </ToggleElement>
      <ToggleElement isActive={!isActive} isOnSwitch={false}>
        Off
      </ToggleElement>
    </StyledToggle>
  )
}
