import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NavLink, Link as HistoryLink } from 'react-router-dom'

import { ArrowLeft } from 'react-feather'
import { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 1.25rem 1.25rem 0 0 ;
  height: 3.38rem;
  justify-content: space-evenly;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  outline: none;
  width: 50%;
  cursor: pointer;
  text-decoration: none;
  font-size: 20px;
  background: rgba(175,71,248,0.1);
  color: #AF47F8;
  &:nth-child(2) {
    border-radius: 0rem 1.06rem 0rem 1.06rem;
  }
  &:nth-child(1) {
    border-radius: 1.06rem 0rem 1.06rem  0rem ;
  }
  &.${activeClassName} {
    color: #651B97;
    background: transparent;
    border-radius: none;
    font-weight: 500;
  }

  :hover,
  :focus {
    color: #AF47F8;
  }
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  const { t } = useTranslation()
  return (
    <Tabs style={{ marginBottom: '30px' }}>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
        {t('swap')}
      </StyledNavLink>
      <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
        {t('pool')}
      </StyledNavLink>
    </Tabs>
  )
}

export function FindPoolTabs() {
  const { t } = useTranslation()
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{t('NavigationTabsTitle')}</ActiveText>
        <QuestionHelper text={t('NavigationTabsTitleTips')} />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  const { t } = useTranslation()
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{adding ? t('AddRemoveTabsTitleAdd') : t('AddRemoveTabsTitleRemove')}{t('AddRemoveTabsTitle')}</ActiveText>
        <QuestionHelper
          text={
            adding
              ? t('AddRemoveTabsTitleTips1')
              : t('AddRemoveTabsTitleTips2')
          }
        />
      </RowBetween>
    </Tabs>
  )
}
