/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
// import { BigNumber } from '@ethersproject/bignumber'
// import { TransactionResponse } from '@ethersproject/providers'
import COOKIE_GODE from '../../assets/svg/cookie-gode.svg'
import RIGHT_ARROW from '../../assets/svg/right-arrow.svg'
import { useTranslation } from 'react-i18next'
// import { useActiveWeb3React } from '../../hooks'
// import { calculateGasMargin, getRouterContract, getFactoryContract } from '../../utils'

const BuyGodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .buy-left {
    display: flex;
    align-items: center;
  }
  a {
    text-decoration: none;
  }
`

const BuyGodeTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Alibaba-PuHuiTi-Medium';
  font-weight: normal;
  color: #E0D3E8;
  line-height: 1.06rem;
  margin-left: 0.38rem;
  margin-right: 1.88rem;
`

const BuyGodeBtn = styled.button`
  min-width: 8.38rem;
  height: 2.13rem;
  background: #D8138D;
  box-shadow: inset 0rem -0.13rem 0rem 0rem rgba(0,0,0,0.2);
  border-radius: 1.06rem;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  span {
    font-size: 0.88rem;
    font-family: 'Alibaba-PuHuiTi-Medium';
    font-weight: normal;
    color: #FFFFFF;
    line-height: 1rem;
  }
  img {
    width: 1.5rem;
    margin-left: 0.19rem;
  }
`

export default function BuyGode() {
  // const { account, chainId, library } = useActiveWeb3React()
  const { t } = useTranslation()

  // useEffect(() => {
  //   if (account) getGodeUsd()
  // }, [account])

  // const getGodeUsd = async () => {
  //   try {
  //     if (!chainId || !library || !account) return
  //   const router = getFactoryContract(chainId, library, account)
  //   console.log('router',router)
  //   let params = await router
  //     .getPair('0x0000000000000000000000000000000000000000','0xbB27915e01Ee9f8F9fBA220B1D1475A20668819D')
  //   console.log('params', params)
  //   } catch (error) {
  //     console.error('error', error)
  //   }

  // }

  // async function getGodeUsd1()  {
  //   if (!chainId || !library || !account) return
  //   const router = getRouterContract(chainId, library, account)

  //   let estimate,
  //     method: (...args: any) => Promise<TransactionResponse>,
  //     args: Array<string | string[] | number>,
  //     value: BigNumber | null
  //   estimate = router.estimateGas.getAmountsOut
  //   method = router.getAmountsOut
  //   args = [
  //     1,
  //     ['0x0000000000000000000000000000000000000000']
  //   ]
  //   value = null
  //   await estimate(...args, value ? { value } : {})
  //     .then(estimatedGasLimit =>
  //       method(...args, {
  //         ...(value ? { value } : {}),
  //         gasLimit: calculateGasMargin(estimatedGasLimit)
  //       }).then(response => {
  //         console.log('response',response)
  //       })
  //     )
  //     .catch(error => {
  //       console.error('error',error)
  //     })
  // }

  return <BuyGodeWrapper>
    {/* <div className="buy-left">
      <img src={COOKIE_GODE} alt="gode" />
      <BuyGodeTitle>$4.497</BuyGodeTitle>
    </div> */}
    <a href="https://cookieswap.vip/#/swap" target="_blank" rel="noopener noreferrer">
    <BuyGodeBtn>
      <span>{t('FooterBuyGode')}</span>
      <img src={RIGHT_ARROW} alt="right-arrow" />
    </BuyGodeBtn></a>
  </BuyGodeWrapper>
}