import { ChainId } from 'star-net-uniswap-sdk'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import Bridge from '../../assets/images/bridge.png'
import Logo from '../../assets/svg/logo.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'
import Settings from '../Settings'
// import Menu from '../Menu'

import Row, { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
import SwitchLanguage from '../Footer/Switch'
// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  background: #fff;
  height: 6.25rem;
  padding: 0 10.41%;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 1.25rem;
    height: 3rem;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    .cookie-nft-a {
      display: none;
    }
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
  .cookie-nft-a {
    display: none;
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
    position: absolute;
    right:50px;
    bottom: calc(-25px - 1.5rem);
    .cookie-nft-a {
      display: block;
    }
  `};
`
const BridgeBox = styled.a`
  border: 1px solid rgb(216, 19, 141);
  padding: 8px 12px;
  border-radius: 12px;
  margin: 0 10px;
  text-decoration: none;
  color: rgb(216, 19, 141);
  img {
    margin-right: 6px;
    width: 12px;
    height: 12px;
  }
`

const BridgeBoxH5 = styled.a`
  padding: 8px 12px;
  border-radius: 12px;
  margin: 0 10px;
  text-decoration: none;
  color: rgb(216, 19, 141);
  white-space: nowrap;
  img {
    margin-right: 6px;
    width: 12px;
    height: 12px;
  }
`

const Title = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const TitleText = styled(Row)`
  font-size: 1.25rem;
  font-family: 'Alibaba-Sans-Black';
  font-weight: normal;
  color: #1a1a1a;
  line-height: 1.5rem;
  margin-left: 0.63rem;
  text-decoration: none;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const TitleTextNft = styled(Row)`
  font-size: 1rem;
  font-family: 'Alibaba-Sans-Black';
  font-weight: normal;
  margin-left: 4.63rem;
  cursor: pointer;
  color: #651b97;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-left: 0.63rem;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
  display: none;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const UniIcon = styled.div`
  img {
    width: 5.5rem;
  }
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img { 
      width: 4rem;
    }
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `};
`

const BalanceText = styled(Text)`
  color: #651b97;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.WGODE]: 'GODE',
  [ChainId.GODETest]: 'GODETest'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const userEthBalance = useETHBalances([account])[account]
  const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame>
      <RowBetween style={{ alignItems: 'center' }}>
        <HeaderElement>
          <Title href=".">
            <UniIcon>
              <img src={isDark ? Logo : Logo} alt="logo" />
            </UniIcon>
            <TitleText style={{ display: 'none' }}>Cookieswap</TitleText>
          </Title>
          {/* <Title
            className="cookie-nft-a"
            href="https://nft.cookieswap.vip/#/market"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TitleTextNft>Cookie NFT</TitleTextNft>
          </Title> */}
        </HeaderElement>
        <HeaderControls>
          {!isMobile ? (
            <BridgeBox href="https://bridge.cookieswap.vip/">
              <img src={Bridge}></img>
              {t('bridge')}
            </BridgeBox>
          ) : null}

          <HeaderElement>
            <TestnetWrapper>
              {!isMobile && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
            </TestnetWrapper>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} GODE
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            {/* <Title
              className="cookie-nft-a"
              href="https://nft.cookieswap.vip/#/market"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TitleTextNft>Cookie NFT</TitleTextNft>
            </Title> */}
            {isMobile ? (
              <BridgeBoxH5 href="https://bridge.cookieswap.vip/">
                <img src={Bridge}></img>
                {t('bridge')}
              </BridgeBoxH5>
            ) : null}
            <SwitchLanguage textShow={false} />
            {/* <VersionSwitch /> */}
            <Settings />
            {/* <Menu /> */}
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
