import React from 'react'
import styled, { css } from 'styled-components'
import Logo from '../../assets/images/footer-logo.svg'
import ICON1 from '../../assets/svg/icon/twitter.svg'
import ICON2 from '../../assets/svg/icon/telegram.svg'
import ICON3 from '../../assets/svg/icon/facebook.svg'
import ICON4 from '../../assets/svg/icon/discord.svg'
import { useTranslation } from 'react-i18next'

import BuyGode from './BuyGode'
import Switch from './Switch'

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  .footer-bottom {
    display: flex;
    justify-content: space-between;
  }
  .footer-top {
    display: none;
  }
  ${({ theme }) =>
    theme.mediaWidth.upToExtraSmall(
      () => css`
        .footer-bottom {
          display: none;
        }
        .footer-top {
          display: block;
          .logos {
            img {
              transition: transform 0.3s ease;
              :hover {
                transform: rotate(-5deg);
              }
              width: 5.5rem;
            }
          }
          .buy-gode {
            margin-top: 3rem;
            margin-bottom: 1.56rem;
          }
        }
      `
    )}
`

const Title = styled.div`
  font-size: 1rem;
  font-family: 'Alibaba-PuHuiTi-Medium';
  font-weight: 100;
  color: #af47f8;
  line-height: 1.06rem;
  ${({ theme }) =>
    theme.mediaWidth.upToExtraSmall(
      () => css`
        margin-top: 1.88rem;
      `
    )}
`

const MenuList = styled.div`
  display: flex;
  justify-content: space-between;
  .list {
    display: flex;
    flex-direction: column;
    img {
      width: 5.5rem;
      transition: transform 0.3s ease;
      :hover {
        transform: rotate(-5deg);
      }
    }
  }
  ${({ theme }) =>
    theme.mediaWidth.upToExtraSmall(
      () => css`
        flex-direction: column;
        .list:nth-child(4) {
          display: none;
        }
      `
    )}
`

const MenuTitle = styled.a`
  text-decoration: none;
  color: #e0d3e8;
  margin-top: 0.75rem;
  font-size: 1rem;
  font-family: 'Alibaba-PuHuiTi-Medium';
  font-weight: 100;
  color: #e0d3e8;
  line-height: 1.06rem;
  &:hover {
    cursor: pointer;
    color: #ffc300;
  }
`

const IconListDiv = styled.div`
  margin-top: 6.88rem;
  ${({ theme }) =>
    theme.mediaWidth.upToExtraSmall(
      () => css`
        margin: 0 0 3.13rem 0;
      `
    )}
`

const IconTitle = styled.a`
  margin-right: 1.88rem;
  img {
    width: 24px;
  }
`

const FooterLine = styled.div`
  background: rgba(175, 71, 248, 0.1);
  width: 100%;
  height: 1px;
  margin: 1.88rem 0 2.5rem 0;
  ${({ theme }) =>
    theme.mediaWidth.upToExtraSmall(
      () => css`
        display: none;
      `
    )}
`

const FooterLineH5 = styled.div`
  display: none;
  background: rgba(175, 71, 248, 0.1);
  width: 100%;
  height: 1px;
  margin: 2.5rem 0 3rem 0;
  ${({ theme }) =>
    theme.mediaWidth.upToExtraSmall(
      () => css`
        display: block;
      `
    )}
`

interface ListType {
  name: string
  list: { title: string; url: string }[]
}

const iconList: { icon: string; url: string; title: string }[] = [
  { icon: ICON1, url: 'https://twitter.com/CookieSwapPro', title: 'twitter' },
  { icon: ICON2, url: 'https://t.me/CookieSwap_Global', title: 'telegram' },
  { icon: ICON3, url: 'https://www.facebook.com/profile.php?id=100087338572351', title: 'facebook' },
  { icon: ICON4, url: '', title: 'discord' }
]

export default function Footer() {
  const { t } = useTranslation()

  const footerListMenu = (): ListType[] => {
    const initFooterList: ListType[] = [
      {
        name: 'GODE Ecosystem',
        list: [
          { title: 'Cookie Swap', url: 'https://cookieswap.vip/' },
          { title: 'CrossChain Bridge', url: 'https://bridge.cookieswap.vip/' },
          { title: 'Cookie NFT', url: '' },
          { title: 'Multi-sig Wallet', url: '' }
        ]
      },
      {
        name: 'DEFI',
        list: [
          { title: 'GODE Miner', url: '' },
          { title: 'Bayesian Computing', url: '' },
          { title: '100% Sharing', url: '' },
          { title: 'YHB System', url: '' }
        ]
      },
      {
        name: 'HELP',
        list: [
          { title: 'MetaGod Wallet', url: '' },
          { title: 'MetaMedia', url: '' },
          { title: 'MetaChat', url: '' }
        ]
      }
    ]
    return initFooterList
  }

  const init = footerListMenu()

  return (
    <FooterContent>
      <div className="footer-top">
        <a href="." className="logos">
          <img src={Logo} alt="logo" />
        </a>
        <div className="buy-gode">
          <BuyGode />
        </div>
      </div>
      <FooterLineH5 />
      <MenuList>
        {init.map((item, i) => (
          <div className="list" key={i}>
            <Title>{item.name}</Title>
            {item.list.map((ite, ids) => (
              <MenuTitle href={ite.url === '' ? '.' : ite.url} target="_blank" key={ids}>
                {ite.title}
              </MenuTitle>
            ))}
          </div>
        ))}
        <div className="list">
          <a href="https://coinhome.pro/" target="_blank">
            <img src={Logo} alt="logo" />
          </a>
        </div>
      </MenuList>
      <FooterLineH5 />
      <IconListDiv>
        {iconList.map((item, index) => (
          <IconTitle href={item.url === '' ? '.' : item.url} target="_blank" key={index}>
            <img src={item.icon} alt={item.title} />
          </IconTitle>
        ))}
      </IconListDiv>
      <FooterLine />
      <div className="footer-bottom">
        <Switch />
        <BuyGode></BuyGode>
      </div>
    </FooterContent>
  )
}
