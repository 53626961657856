import React from 'react'
import styled from 'styled-components'
import BODYBG from '../assets/images/body-bg-modal.png'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: url(${BODYBG}) no-repeat;
  background-size: 100% 100%;
  padding: 0.06rem 0.5rem;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
