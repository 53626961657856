import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import LANGUAGE from '../../assets/svg/language.svg'
import LANGUAGE_TOP from '../../assets/svg/language-top.svg'
import { useTranslation } from 'react-i18next'
import Popover from '../Popover'

const LangugaeDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 35px;
  padding: 0.15rem 0 0.15rem 0.5rem;
  img{
    width: 28px;
    height: auto;
  }
  span {
    font-size: 0.88rem;
    font-family: 'Alibaba-PuHuiTi-Medium';
    font-weight: normal;
    color: #E0D3E8;
    line-height: 1.5rem;
  }
  &:hover {
    opacity: 0.8;
  }
`

const SwitchWrapper = styled.div`
  
`

interface Types {
  textShow?: boolean
}

const langageList: {code: string; language: string}[] = [
  { code: 'zh-CN', language: '中文简体' },
  { code: 'en', language: 'English' },
  { code: 'vi', language: 'Tiếng Việt' },
  { code: 'AR', language: 'العربية' },
  { code: 'JA', language: '日本語' },
  { code: 'ru', language: 'Русский' },
  { code: 'KO', language: '한국어' },
  { code: 'sp', language: 'Spanish' },
  { code: 'ti', language: 'ภาษาไทย' },
]

export default function Switch({textShow = true}: Types) {
  const { i18n } = useTranslation()
  const [show, setShow] = useState(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  const onChangeSwtich = (str: string) => {
    (i18n as any).changeLanguage(str)
    void close()
  }

  return <SwitchWrapper>
    <Popover content={
      <div className="language-popover" onMouseLeave={close}>
        {langageList.map((item, index) => 
          <p style={{color: (i18n as any).language === item.code && '#FFC300'}} key={index} onClick={() => onChangeSwtich(item.code)}>
            {item.language}
          </p>
        )}
      </div>
    } show={show} children={
      <LangugaeDiv onMouseEnter={open}>
        <img src={textShow ? LANGUAGE: LANGUAGE_TOP} alt="language" />
        {textShow && <span style={{marginLeft: '0.38rem'}}>{(i18n as any).language}</span>}
      </LangugaeDiv>
    } placement={textShow ? 'auto' : 'bottom'}/>
  </SwitchWrapper>
}